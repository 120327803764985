
import React, { FC } from 'react';
import classNames from 'classnames';

import './Icon.scss';
import Arrow from '../../assets/icons/icon_arrow.svg?react';
import ArrowDown from '../../assets/icons/icon_arrow-down.svg?react';
import Calendar from '../../assets/icons/icon_calendar.svg?react';
import Moon from '../../assets/icons/icon_moon.svg?react';
import Person from '../../assets/icons/icon_person.svg?react';
import Suitcase from '../../assets/icons/icon_suitcase.svg?react';
import TrashBin from '../../assets/icons/icon_trash-bin.svg?react';
import Close from '../../assets/icons/icon_close.svg?react';
import PersonFace from '../../assets/icons/icon_person_face.svg?react';
import WomanOpen from '../../assets/icons/icon_woman_open.svg?react';
import WomanClosed from '../../assets/icons/icon_woman_closed.svg?react';
import ChildOpen from '../../assets/icons/icon_child_open.svg?react';
import ChildClosed from '../../assets/icons/icon_child_closed.svg?react';
import BabyOpen from '../../assets/icons/icon_baby_open.svg?react';
import BabyClosed from '../../assets/icons/icon_baby_closed.svg?react';

import { IconList, IconProps } from './Icon.types';

export const iconList: IconList = {
  arrow: <Arrow />,
  arrowDown: <ArrowDown />,
  calendar: <Calendar />,
  moon: <Moon />,
  person: <Person />,
  suitcase: <Suitcase />,
  close: <Close />,
  'trash-bin': <TrashBin />,
  personFace: <PersonFace />,
  womanOpen: <WomanOpen />,
  womanClosed: <WomanClosed />,
  childOpen: <ChildOpen />,
  childClosed: <ChildClosed />,
  babyOpen: <BabyOpen />,
  babyClosed: <BabyClosed />,
};



const Icon: FC<IconProps> = ({ name, className, size = 'small' }) => (
  <div
    className={classNames('react-icon', className, {
      [`react-icon--size-${size}`]: size,
    })}
  >
    <>{iconList[name] || <img src={`/icons/icon_${name}.svg`} alt={name} />}</>
  </div>
);

export default Icon;
